









































































import Vue from "vue";
import { PropType } from "vue";
import { mapState } from "vuex";
import { Game } from "@/types";

export default Vue.extend({
  name: "GameCard",

  props: {
    game: {
      type: Object as PropType<Game>,
      required: true,
    },
  },

  computed: {
    ...mapState(["appUrl"]),

    imgUrl(): string {
      return this.game?.slug ? `${this.appUrl}img/${this.game.slug}.jpg` : "";
    },
  },
});
