
























import Vue from "vue";
import { mapState } from "vuex";

export default Vue.extend({
  name: "rules-tapestry",

  data: () => ({
    selectedTab: "",
    tabs: [
      {
        title: "FAQ",
        slug: "faq",
      },
    ],
  }),

  computed: {
    ...mapState(["appUrl"]),
  },

  watch: {
    "$route.hash": {
      handler: function (hash) {
        this.selectedTab = hash?.substring(1) || "faq";
      },
      immediate: true,
    },
  },
});
