

































































import Vue from "vue";
import { mapActions, mapState } from "vuex";

export default Vue.extend({
  name: "mtg-sets",

  data: () => ({
    loading: true,
    headers: [
      { text: "Sortie", value: "releaseDate" },
      { text: "Nom", value: "name" },
      { text: "Bloc", value: "block" },
      { text: "Code", value: "code" },
      { text: "Type", value: "type" },
    ],
    search: "",
    imgErrors: [] as string[],
  }),

  async beforeMount() {
    this.$store.dispatch("getMtgSets");
  },

  computed: {
    ...mapState(["appUrl", "mtgSets"]),
  },

  watch: {},

  methods: {
    ...mapActions(["getGames"]),

    isImgError(code: string): boolean {
      return this.imgErrors.includes(code);
    },
  },
});
