var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-app-bar',{attrs:{"hide-on-scroll":"","dark":"","dense":"","app":""}},[_c('div',{staticClass:"d-flex align-center"},[_c('v-img',{staticClass:"shrink mr-2",attrs:{"alt":"Meeple","contain":"","src":(_vm.appUrl + "favicon.svg"),"width":"25","transition":"scroll-x-transition"}}),_c('v-btn',{attrs:{"plain":"","to":{ name: 'Home' }}},[_c('v-toolbar-title',[_vm._v("Jeux de société")])],1),_c('v-menu',{attrs:{"offset-y":"","transition":"expand-transition"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"plain":""}},'v-btn',attrs,false),on),[_vm._v(" Scores ")])]}}])},[_c('v-list',_vm._l(([
            { title: 'Scythe', page: 'ScoresScythe', slug: 'scythe' },
            { title: 'Tapestry', page: 'ScoresTapestry', slug: 'tapestry' } ]),function(item){return _c('v-list-item',{key:item.slug,attrs:{"to":{ name: item.page }}},[_c('v-list-item-title',{domProps:{"textContent":_vm._s(item.title)}})],1)}),1)],1),_c('v-menu',{attrs:{"offset-y":"","transition":"expand-transition"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
          var on = ref.on;
          var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"plain":""}},'v-btn',attrs,false),on),[_vm._v(" Règles & Aides ")])]}}])},[_c('v-list',_vm._l(([
            {
              title: '7 Wonders',
              page: 'RulesSevenWonders',
              slug: 'seven-wonders-rules',
            },
            {
              title: 'Tapestry',
              page: 'RulesTapestry',
              slug: 'tapestry-rules',
            } ]),function(item){return _c('v-list-item',{key:item.slug,attrs:{"to":{ name: item.page }}},[_c('v-list-item-title',{domProps:{"textContent":_vm._s(item.title)}})],1)}),1)],1),_c('v-menu',{attrs:{"offset-y":"","transition":"expand-transition"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
          var on = ref.on;
          var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"plain":""}},'v-btn',attrs,false),on),[_vm._v(" MTG ")])]}}])},[_c('v-list',_vm._l(([
            {
              title: 'Capacités',
              page: 'MtgAbilities',
              slug: 'mtg-abilities',
            },
            {
              title: 'Éditions',
              page: 'MtgSets',
              slug: 'mtg-sets',
            } ]),function(item){return _c('v-list-item',{key:item.slug,attrs:{"to":{ name: item.page }}},[_c('v-list-item-title',{domProps:{"textContent":_vm._s(item.title)}})],1)}),1)],1)],1),_c('v-spacer'),_c('v-btn',{attrs:{"icon":"","link":"","href":_vm.author.url}},[_vm._v(" }:§ ")])],1)}
var staticRenderFns = []

export { render, staticRenderFns }