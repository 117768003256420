



























































































































































































































import Vue from "vue";
import { mapActions, mapState } from "vuex";
import GameCard from "@/components/GameCard.vue";
import { Game } from "@/types";

export default Vue.extend({
  name: "GamesList",

  components: {
    GameCard,
  },

  data: () => ({
    loading: true,
    filteredGames: [] as Game[],
    itemsPerPage: -1,

    drawer: true,
    mini: true,
    search: "",

    playersRange: {
      min: 1,
      max: 6,
    },
    durationRange: {
      min: 30,
      max: 120,
    },
    themes: [] as string[],
    filtersActive: {
      players: false,
      playersRange: false,
      duration: false,
      durationRange: false,
      theme: false,
    },
    filtersValue: {
      players: 4,
      playersRange: [2, 6],
      duration: 30,
      durationRange: [30, 60],
      theme: "",
    },

    sortDesc: true,
    sortBy: "bggWeight",
    attributes: [
      {
        label: "Difficulté",
        key: "bggWeight",
      },
      {
        label: "Durée min.",
        key: "duration.min",
      },
      {
        label: "Durée max.",
        key: "duration.max",
      },
      {
        label: "Nb. joueurs min.",
        key: "players.min",
      },
      {
        label: "Nb. joueurs max.",
        key: "players.max",
      },
      {
        label: "Nom",
        key: "title",
      },
      {
        label: "Thème",
        key: "theme",
      },
    ] as { label: string; key: string }[],
  }),

  async beforeMount() {
    if (this.games.length) {
      this.init();
    } else {
      this.$store.dispatch("getGames").then(() => this.init());
    }
  },

  computed: {
    ...mapState(["games"]),
  },

  watch: {
    filtersActive: {
      handler: function () {
        this.applyFilters();
      },
      deep: true,
    },
    filtersValue: {
      handler: function () {
        this.applyFilters();
      },
      deep: true,
    },
  },

  methods: {
    ...mapActions(["getGames"]),

    init(): void {
      this.filteredGames = [...this.games];
      this.setDefaultValues();
      this.loading = false;
    },

    setDefaultValues(): void {
      this.filteredGames.forEach((game) => {
        if (!game.players.max) {
          game.players.max = game.players.min;
        }
        if (game.players.max > this.playersRange.max) {
          this.playersRange.max = game.players.max;
        }
        if (!game.duration.max) {
          game.duration.max = game.duration.min;
        }
        if (game.duration.min < this.durationRange.min) {
          this.durationRange.min = game.duration.min;
        }
        if (game.duration.max > this.durationRange.max) {
          this.durationRange.max = game.duration.max;
        }
        if (!this.themes.includes(game.theme)) {
          this.themes.push(game.theme);
        }
      });
      this.themes.sort();
      this.filtersValue.theme = this.themes[0];
    },

    applyFilters(): void {
      this.filteredGames = this.games.filter(
        (game: Game) =>
          this.filterGameByPlayers(game) &&
          this.filterGameByDuration(game) &&
          this.filterGameByTheme(game)
      );
    },

    filterGameByPlayers(game: Game): boolean {
      if (!this.filtersActive.players) {
        return true;
      }
      const [min, max] = this.filtersActive.playersRange
        ? this.filtersValue.playersRange
        : Array(2).fill(this.filtersValue.players);
      return game.players.min <= min && game.players.max >= max;
    },

    filterGameByDuration(game: Game): boolean {
      if (!this.filtersActive.duration) {
        return true;
      }
      const [min, max] = this.filtersActive.durationRange
        ? this.filtersValue.durationRange
        : Array(2).fill(this.filtersValue.duration);
      return game.duration.min <= min && game.duration.max <= max;
    },

    filterGameByTheme(game: Game): boolean {
      if (!this.filtersActive.theme) {
        return true;
      }
      return game.theme === this.filtersValue.theme;
    },
  },
});
