











































import Vue from "vue";
import { mapState } from "vuex";

export default Vue.extend({
  name: "rules-seven-wonders",

  data: () => ({
    selectedTab: "",
    tabs: [
      {
        title: "Règles rapides",
        slug: "quick-rules",
      },
      {
        title: "Merveilles",
        slug: "wonders",
      },
      {
        title: "Symboles",
        slug: "symbols",
      },
      {
        title: "FAQ",
        slug: "faq",
      },
      {
        title: "PDF",
        slug: "pdf",
      },
    ],
    docs: ["wonder-pack.pdf", "leaders.pdf", "cities.pdf", "armada.pdf"],
  }),

  computed: {
    ...mapState(["appUrl"]),
  },

  watch: {
    "$route.hash": {
      handler: function (hash) {
        this.selectedTab = hash?.substring(1) || "quick-rules";
      },
      immediate: true,
    },
  },
});
