







































import Vue from "vue";
import { PropType } from "vue";
import { TapestryPlay, TapestryPlayer } from "@/types";

export default Vue.extend({
  name: "TapestryScoresPlay",

  props: {
    play: {
      type: Object as PropType<TapestryPlay>,
      required: true,
    },
    mainPlayerId: {
      type: Number,
      default: 1,
    },
  },

  methods: {
    automaLevelLitteral(value: number): string {
      switch (value) {
        case 2:
          return "le Moyen";
        case 3:
          return "le Légèrement Intimidant";
        case 4:
          return "le Quelque Peu Impressionnant";
        case 5:
          return "le Définitivement Incroyable";
        case 6:
          return "le Briseur de Rêves";
        default:
          return "le Sous Performant";
      }
    },
  },
});
