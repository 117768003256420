





































import Vue from "vue";
import { PropType } from "vue";
import TapestryScoresPlay from "@/components/TapestryScoresPlay.vue";
import { TapestryCivilization, TapestryPlay } from "@/types";

export default Vue.extend({
  name: "TapestryScoresTable",

  components: {
    TapestryScoresPlay,
  },

  props: {
    civilizations: {
      type: Array as PropType<TapestryCivilization[]>,
      required: true,
      default: [],
    },
    plays: {
      type: Array as PropType<TapestryPlay[]>,
      required: true,
      default: [],
    },
    mainPlayerId: {
      type: Number,
      default: 1,
    },
  },

  computed: {
    sortedCivilizations(): TapestryCivilization[] {
      return this.civilizations
        .filter((civ) => !civ.automa)
        .sort(this.sortByName);
    },
  },

  methods: {
    sortByName(a: TapestryCivilization, b: TapestryCivilization): number {
      return a.name.localeCompare(b.name);
    },

    getPlaysByCiv(civSlug: string): TapestryPlay[] {
      return this.plays.filter(
        (play) =>
          play.players.find((player) => player.id === this.mainPlayerId)
            ?.civilization.slug === civSlug
      );
    },
  },
});
