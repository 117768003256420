












import Vue from "vue";
import { mapState } from "vuex";

export default Vue.extend({
  name: "AppFooter",

  computed: {
    ...mapState(["appUrl", "author", "appVersion", "vueUrl", "vueVersion"]),
  },
});
