



























































































import Vue from "vue";
import { mapState } from "vuex";

export default Vue.extend({
  name: "AppHeader",

  computed: {
    ...mapState(["appUrl", "author"]),
  },
});
